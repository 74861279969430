<template>
  <div class="icon-selector" v-if="show">
    <div  @click="visible=true"  :style="{backgroundColor: t_color}" class="selected_icon">
      <img :src="t_icon">
    </div>
    <el-upload class="icon-upload"
               ref="iconUpload"
               :show-file-list="false"
               action="/business/upload"
               :before-upload="beforeUpload"
               :http-request="uploadIcon">
      <div class="icon-upload-img">+</div>
    </el-upload>

    <template v-if="visible">
      <div class="pop-up">
        <div class="icons">
          <div v-for="(iconone,index) of iconList"
               :key="iconone.iconUrl"
               class="icon"
               :class="index == selectIconIndex?'active':''"
               @click="selectIconIndex = index">
            <img :src="iconone.iconUrl" alt="" />
          </div>
        </div>
        <div class="colors">
          <el-color-picker v-model="t_color" :disabled="disabledSelectIcon" :predefine="predefineColors"></el-color-picker>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import uploadCommon from "../../../../utils/upload";

function isChildOf(child, parent) {
  var parentNode;
  if(child && parent) {
    parentNode = child.parentNode;
    while(parentNode) {
      if(parent === parentNode) {
        return true;
      }
      parentNode = parentNode.parentNode;
    }
  }
  return false;
}

export default {
  name: "edit-icon",
  props:{
    color:{
      type:String,
      default:""
    },
    icon:{
      type:String,
      default:""
    },
    show:{
      type:Boolean,
      default:false
    },
    immediate:{
      type:Boolean,
      default:false
    }

  },
  data(){
    return {
      selectIconIndex:0,
      iconList:[],
      visible:false,
      predefineColors:['#00D9D8','#9B64FF','#00A8FF','#FFA300','#FF692F','#FF3A69'],
    }
  },
  computed:{
    t_icon:{
      set(val){
        this.$emit('update:icon',val);
      },
      get(){
        let res = this.iconList[this.selectIconIndex] && this.iconList[this.selectIconIndex].iconUrl;
        res && this.$emit('update:icon',res);
        return res||this.icon;
      }

    },
    t_color:{
      get(){
        return this.color;
      },
      set(val){
        this.$emit("update:color",val)
      }
    },
    disabledSelectIcon(){
      return this.iconList.findIndex(e => e.iconUrl == this.icon) <= -1
    }
  },
  watch:{
    color(val) {
      if (!val) {
        this.t_color = this.predefineColors[0];
      }
    },
    // icon:{
    //   immediate:true,
    //   handler(val) {
    //     this.disabledSelectIcon = this.iconList.findIndex(e => e.iconUrl == val) <= -1;
    //   }
    // },
    visible(val){
      if(!val){
        document.body.removeEventListener('click', this.close)
        this.$emit('closeIconEdit',val)
      }else{
        setTimeout(() => {
          document.body.addEventListener('click', this.close)
        })
      }
    },
    show:{
      immediate:true,
      handler(val){
        if(this.immediate&&val){
          this.visible = true;
          this.init()
        }
      }
    }
  },
  created(){
    if(!this.immediate){
      this.init()
    }
  },
  methods:{
    close(e){
      if(isChildOf(e.target,document.querySelector('.pop-up'))){
        return ;
      }else{
        this.visible = false;
      }
    },
    init(){
      if (!this.color&&!this.icon) {
        this.$emit("update:color", this.predefineColors[0]);
      }else if(!this.color){
        this.$emit("update:color", '#fff');
      }
      this.getIcons();

    },
    getIcons(){
      this.$api.sysapi.getIconList().then(({code,data,msg})=>{
        this.iconList = data;
        if(this.icon){
          this.selectIconIndex = this.iconList.findIndex(e=>e.iconUrl == this.icon) || -1
        }
      })
    },

    uploadIcon({file}){
      let formData = new FormData();
      formData.append('file',file);
      this.$api.sysapi.uploadFile(formData,'menuicon').then(({code,data,msg})=>{
        this.t_icon = data;
        this.t_color = '#ffffff';
        this.selectIconIndex=-1;
        // 禁用背景色

      })
    },
    //  文件上传前校验
    beforeUpload(file){
      //验证文件格式和大小最大100M
      return uploadCommon.checkFileAndSize(file,0.1,['png','jpg','jpeg']);
    },
  }
}
</script>

<style scoped lang="scss">
.icon-selector {
  position: relative;
  display: inline-block;

  .selected_icon {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    padding: 12px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon-upload{
    .icon-upload-img{
      width: 50px;
      height: 50px;
      border: 1px dotted #afabae;
      border-radius: 12px;
      font-size: 20px;
      color: #c3c1c2;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 100%;
      margin-left: 10px;
      box-sizing:border-box
    }
  }

  .pop-up {
    width: 240px;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid lightgray;
    border-radius: 8px;
    background-color: white;
    padding: 16px;
    z-index: 9;
    margin-left: 8px;

    .icons {
      display: flex;
      flex-wrap: wrap;
      height: 120px;
      border-bottom: 1px solid lightgray;
      overflow: auto;

      .icon {
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-right: 8px;
        margin-bottom: 8px;
        box-sizing: border-box;
        padding: 8px;
        border-radius: 8px;
        overflow: hidden;
        filter: invert(24%);

        &:hover {
          filter: invert(80%);
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .active {
        filter: invert(80%);
      }
    }

    .colors {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      .color {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
}
</style>