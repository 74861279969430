

<!--  场馆选择： 只有超级管理员才可以选择场馆，并且只有选择了系统场馆，才可以控制某个菜单的显隐权限，选择其他场馆，只能修改名字和图标-->
<!--  场馆下的管理员，只能修改自己场馆的菜单的名字和图标，不能修改菜单的显隐权限-->
<template>
  <div class="content-wrapper">
    <div class="project-content" v-if="userType==1">
      <label class="label">场馆</label>
     <el-select v-model="project" placeholder="请选择" size="medium">
       <el-option v-for="ve in projectList" :key="ve.id" :label="ve.name" :value="ve.id"></el-option>
     </el-select>
    </div>
    <div class="menu-content clearfix">
      <div class="operate-block">
        <el-button size="small" type="primary" @click="syncMenu" v-if="!commonAdminAuth">同步菜单</el-button>
        <el-button size="small" type="primary" @click="addMenu" v-if="!commonAdminAuth">新增菜单</el-button>
        <el-button size="small" type="primary" @click="editMenu">修改菜单</el-button>
      </div>
      <el-tabs v-model="activeTab">
        <el-tab-pane label="后台权限" name="after">
          <backstage-menu ref="backstagesRef" :menu.sync="backstages"
                          :editable="editable"
                          :canCutMenu="!commonAdminAuth"></backstage-menu>
        </el-tab-pane>
        <el-tab-pane label="前台权限" name="before">
          <front-menu :menu.sync="fronts"
                      :editable="editable"
                      :canCutMenu="!commonAdminAuth"></front-menu>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bottom">
      <el-button size="medium" @click="cancel" :disabled="!editable">取消</el-button>
      <el-button size="medium" type="primary" @click="confirm" :disabled="!editable">确定</el-button>
    </div>
    <el-dialog title="新增菜单" :visible.sync="dialogVisible" width="45%">
      <el-form ref="addMenuForm" :model="addItem" :rules="addMenuFormRules" class="add-form">
        <el-form-item label="菜单层级" prop="pid">
          <el-cascader v-model="addItem.pid" placehholder="请选择" :style="{width:'220px'}"
                       size="medium"
                       placeholder="请选择"
                       :options="activeTab=='after'?backstages:fronts"
                       :props="{
                         emitPath:false,
                         value:'id',
                         label:'name',
                         children:'child',
                         checkStrictly:true
                       }">
          </el-cascader>
        </el-form-item>
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="addItem.name" size="medium" maxlength="20" :style="{width:'220px'}"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="iconUrl" v-if="activeTab!=='after'">
          <div class="icon-tips">图片建议：尺寸70*70,支持jpg,png,jpeg格式图片，大小不能超过100kb</div>
          <edit-icon  v-if="dialogVisible"
                      style="margin-left: 64px"
                     :immediate="false"
                     :show.sync="dialogVisible"
                    :icon.sync="addItem.iconUrl"
                    :color.sync="addItem.iconColor">
          </edit-icon>

        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible=false" size="medium">取消</el-button>
        <el-button type="primary" @click="addMenuConfirm" size="medium">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BackstageMenu from "./components/backstage-menu";
import FrontMenu from "./components/front-menu";
import EditIcon from "./components/edit-icon";
import {removeLeaf} from "@/utils/utils";
import {deepClone} from "../../../components/dynamicForm/utils/utils";
export default {
  name: "menuConfig",
  components: {EditIcon, FrontMenu, BackstageMenu},
  data(){
    return {
      project:'',
      projectList:[{
        id:'',
        name:'系统'
      }],
      activeTab:'after',
      backstages:[],
      originBackstages:[],
      fronts:[],
      originFronts:[],
      userMarketId:'', // 超级管理员的marketId为’‘，其他场馆下的管理员的marketId不为空
      editable: false,
      dialogVisible:false,
      addItem:{
        pid:'',
        name:'',
        iconColor:'',
        iconUrl:'',
        canHandle:false,
        platform:1
      },
      addMenuFormRules:{
        pid:this.$formValidator.empty('层级',"change","number"),
        name:this.$formValidator.empty('名称')
      }
    }
  },
  created(){
    this.userMarketId = JSON.parse(localStorage.getItem('userInfo')).marketId;
    this.userType = JSON.parse(localStorage.getItem('userInfo')).type;
    this.getProjects();
    this.getSysModule();
  },

  watch:{
    'project'(){
      this.editable = false;
      this.getSysModule()
    }
  },

  computed:{
    commonAdminAuth(){
      // return this.userMarketId || (!this.userMarketId&&this.project)
      // return this.userType!=1 || (!this.userType!=1&&this.project)
      return !(this.userType === 1 && !this.project)
    }
  },

  methods:{

    // 获取所有的场馆项目
    getProjects(){
      this.$api.sysapi.getVenueList({
        pageSize:10000,
        pageNum:1
        // pageSize:1,
        // pageNum:10000
      }).then(({code,data,msg})=>{
        this.projectList = [...this.projectList,...data.list];
      })
    },

    // 获取系统的权限树
    async getSysModule(){
      let result ;
      if(this.commonAdminAuth){
        result = await this.$api.sysapi.getRoleByMarketId({marketId: this.project || this.userMarketId})
      }else{
        result = await this.$api.sysapi.getSysModuleTree()
      }
      let {code,data} = result;
      data = removeLeaf(data.child,'child')
      if(code===200){
        this.backstages = [data[0]].map(e=>(e.child.sort((a,b)=>{
            return a.code - b.code
          }),e))
        this.originBackstages = deepClone(this.backstages)
        this.fronts = data[1].child
        this.originFronts = deepClone(this.fronts)
      }
    },

    syncMenu() {
      this.$api.sysapi.syncSysModule().then(res => {
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.getSysModule()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    addMenu(){
      this.addItem = {
        pid:'',
        name:'',
        iconColor:'',
        iconUrl:'',
        canHandle:false,
        platform:this.activeTab=='after'?1:2
      }
      this.dialogVisible = true;
      if(this.activeTab=='before'){
        this.addMenuFormRules = Object.assign(this.addMenuFormRules,{
          iconUrl:this.$formValidator.empty('图标',"change")
        })
      }else{
        this.addMenuFormRules.iconUrl && delete this.addMenuFormRules.iconUrl
      }
      this.$nextTick(()=>{
        this.$refs.addMenuForm.clearValidate();
      })
    },

    addMenuConfirm(){
      this.$refs.addMenuForm.validate((validate)=>{
        if(validate){
          this.$api.sysapi.addMenu(this.addItem).then(({code,data,msg})=>{
            if(code==200){
              this.$message.success('添加成功')
              this.dialogVisible = false;
              this.getSysModule();
            }else{
              this.$message.error(msg)
            }
          })
        }
      })
    },

    editMenu(){
      this.editable = true
    },

    cancel(){
      this.editable = false;
      this.backstages =  deepClone(this.originBackstages)
      this.fronts =  deepClone(this.originFronts)
    },

    confirm(){
      let result_backstages = !this.commonAdminAuth?this.deal_backstages(this.backstages):this.backstages;
      const _diff = (origin,now,props)=>{
        let result=true;
        for(let _prop of props){
          if(origin[_prop] !== now[_prop]){
            result = false;
            break;
          }
        }
        return result
      }
      const _back = this.compare(this.originBackstages,result_backstages,(it1,it2)=>{
        let propList = this.commonAdminAuth?['id','name']:['id','name','canHandle']
        return _diff(it1,it2,propList)
      })
      const _front = this.compare(this.originFronts,this.fronts,(before,after)=>{
        let propList = this.commonAdminAuth?['id','name','iconColor','iconUrl']:['id','name','canHandle','iconColor','iconUrl']
        return _diff(before,after,propList)
      })
      let handler;
      if(this.commonAdminAuth){
        handler = this.$api.sysapi.updateMenuByMarketId
      }else {
        // this.userType==1 超级管理员加的条件
        handler = this.$api.sysapi.updateMenu
      }
      handler([..._back,..._front]).then(({code,data,msg})=>{
        if(code===200){
          this.editable = false;
          this.getSysModule();
          this.$message.success(msg)
        }else{
          this.$message.error(msg)
        }
      })
    },

    // 处理后台权限的canhandle属性
    deal_backstages(target){
      //  获取子组件的选中的节点&半选中的节点id
      const checkedIds = this.$refs.backstagesRef.$refs.backstageTree.getCheckedKeys();
      const halfCheckedIds = this.$refs.backstagesRef.$refs.backstageTree.getHalfCheckedKeys();
      return target.map(e=>{
        if(checkedIds.includes(e.id)||halfCheckedIds.includes(e.id)){
          e.canHandle = true;
        }else{
          e.canHandle = false;
        }
        if(e.child) this.deal_backstages(e.child)
        return e
      })
    },

    compare(oldVal, newVal, compareFn, options = {childKey: 'child'}) {
      let res = [];
      const recursive = (arr1, arr2, handler) => {
        for (let i = 0; i < arr1.length; i++) {
          handler(arr1[i], arr2[i]);
          if (arr1[i][options.childKey] && arr1[i][options.childKey].length) {
            recursive(arr1[i][options.childKey], arr2[i][options.childKey], handler);
          }
        }
      }
      recursive(oldVal, newVal, (item1, item2) => {
        if (!compareFn(item1, item2)) {
          let item = Object.assign({}, item2);
          delete item[options.childKey];
          res.push(item);
        }
      });
      return res;
    }
  }
}
</script>

<style scoped lang="scss">
  .content-wrapper{
    background: #fff;
    padding: 20px;
    overflow: auto;
    .project-content{
      padding: 10px  0;
      .label{
        width: 40px;
      }
      .el-select{
        width: 200px;
      }
    }
    .menu-content{
      padding-bottom: 70px;
      .operate-block{
        text-align: right;
      }
    }
    .bottom{
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 56px;
      box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
      text-align: right;
      line-height: 56px;
      padding-right: 50px;
      box-sizing: border-box;
      background-color: #fff;
      z-index:99
    }
    .add-form{
      min-height: 340px;
    }
  }
  .icon-tips{
    color: #8b8686;
    font-size: 12px;
  }
  ::v-deep .el-form-item__error{
    padding-left: 80px;
  }
  ::v-deep .el-tabs__content{
    overflow: auto;
  }
</style>