<template>
  <el-tree :data="t_menu"
           ref="backstageTree"
           :show-checkbox="canCutMenu"
           node-key="id"
           :default-expand-all="true"
           :props="defaultProps"
           :expand-on-click-node="false"
           :default-checked-keys="defaultChecked">
    <span class="editName" slot-scope="{node,data}">
      <span v-if="!data.edit" @click.stop="!node.disabled && labelClick(data)">{{data.name}}</span>
      <el-input v-model="data.name"  maxlength="20" :ref="'back_menu_'+data.id" v-if="data.edit" @blur.native.capture="saveNode(node,data)"></el-input>
    </span>
  </el-tree>
</template>

<script>
export default {
  props: {
    menu: Array,
    editable:{
      type:Boolean,
      default:false
    },
    canCutMenu:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      defaultProps:{
        children:'child',
        label:'name',
        disabled:()=>{
          return !this.editable
        }
      },
      selectBackstage:[],
      defaultChecked:[]
    }
  },
  computed:{
    't_menu':{
      get(){
        this.defaultChecked = []
        this.canCutMenu && this.setDefaultChecked(this.menu);
        return this.menu
      },
      set(val){
        this.$emit('update:menu',val)
      }
    }
  },
  methods: {
    saveNode(node,data){
      const name = data.name.substr(0,20)
      if(!node.disabled ){
        this.$set(data,'edit',false)
        this.$set(data,'name',name||'默认标题')
      }
    },
    setDefaultChecked(target){
      if(target.length > 0){
        target.forEach(ele => {
          if(ele.child && ele.child.length > 0){
            this.setDefaultChecked(ele.child)
          }else{
            if(ele.canHandle && ele.moduleId !== 2 && ele.moduleId !== 3){
              this.defaultChecked.push(ele.id)
            }
          }
        });
      }
    },
    labelClick(data){
      this.$set(data,'edit',true);
      this.$nextTick(()=>{
        this.$refs['back_menu_'+data.id].focus();
      })
    }
  },

}
</script>

<style scoped lang="scss">
.el-tree-node__children > .el-tree-node > .el-tree-node__content{
  font-weight: bold;
}
::v-deep .el-tree-node__content{
  height: 28px
}
</style>